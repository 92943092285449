import api from '@/Api';

const getDefaultState = () => {
  return {
    creditGiven: [],
    convertToBase: false,
    error: null, 
  }
}

const state = getDefaultState();

const mutations = {
  setCreditGiven(state, creditGiven) {
    state.creditGiven = creditGiven
  },
  setConvertToBase(state, convertToBase) {
    state.convertToBase = convertToBase
  },
  setError(state, error) {
    state.error = error;
  },
  clearError(state) {
    state.error = null;
  },
  resetState(state) {
    Object.assign(state,getDefaultState());
  }
}
const getters = {
  creditGivenFiltered: state => {
    return state.creditGiven.filter(r => r.partnerAccountName != null)
  },
  convertToBase: state => {
    return state.convertToBase
  }
}

const actions = {
  async loadCreditGiven({ commit, state }) {
    const params = {
      convertToBase: state.convertToBase
    }

    try {
      const response = await api.getCreditGiven(params);
      console.log("credit given loaded: ", response.data);
      commit('setCreditGiven', response.data.rows);
    } catch (error) {
      console.log("failed to load credit given:", error);
      commit('setError', { 
        message: "Failed to load credit given data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },
  setConvertToBase( { commit, dispatch }, mode) {
    commit('setConvertToBase', mode)
    dispatch('loadCreditGiven')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}