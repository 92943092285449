import api from '@/Api'

const getDefaultState = () => {
  return {
    journalEntryCbData: null,
    lastClosedAccountingDate: null,
    currentRevision: 0,
    error: null,
  }
}

const state = getDefaultState()

const mutations = {
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  setJournalEntryCbData(state, journalEntryCbData) {
    state.journalEntryCbData = journalEntryCbData
    if (state.journalEntryCbData) {
      state.currentRevision = state.journalEntryCbData.entries.length - 1
    }
  },
  setExported(state, exported) {
    if (state.journalEntryCbData) {
      state.journalEntryCbData.entries.find(entry => entry.revision == state.currentRevision).exported = exported
    }
  },
  setLastClosedAccountingDate(state, lastClosedAccountingDate) {
    state.lastClosedAccountingDate = lastClosedAccountingDate
  },
  setCurrentRevision(state,revision) {
    state.currentRevision = revision
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  revisionCount: state => {
    if (state.journalEntryCbData) {
      return state.journalEntryCbData.entries.length
    }
  },
  selectedJournalEntry: state => {
    if (state.journalEntryCbData && state.journalEntryCbData.entries.length > 0) {
      return state.journalEntryCbData.entries.find(entry => entry.revision == state.currentRevision)
    }
    return []
  },
  journalEntryRevisions: state => {
    if (state.journalEntryCbData && state.journalEntryCbData.entries.length > 0) {

      return state.journalEntryCbData.entries.map(e => {
        return { name: e.revision, revision: e.revision, date: e.createdAt, reversal: e.reversal, text: e.revision, value: e.revision}
      });
    }
    return []
  },
  revisionDropdownEnabled: state => {
    return (state.journalEntryCbData && state.journalEntryCbData.entries.length > 1) 
  },
  currentRevision: state => {
    if (state.journalEntryCbData) {
      return state.currentRevision
    }
  }
}

const actions = {
  async loadJournalEntries({ commit }, date) {
    commit('setError', null)
    try {
      const params = { 
        date: date
      }
      const response = await api.getJournalEntryCl(params)

      console.log("journal entry data loaded: ", response.data)
      commit('setJournalEntryCbData', response.data)
    } catch (error) {
      console.log("failed to load journal entry CL data:", error)
      commit('setError', { 
        message: "Failed to load Journal Entry CL Data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },
  setExported( { commit }, exported) {
    commit('setExported', exported)
  },
  setSelectedDate({ commit, dispatch }, date) {
    commit('setSelectedDate', date)
    dispatch('loadJournalEntries')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}