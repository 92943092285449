const getDefaultState = () => {
  return {
    reportType: 'credit-given-table',
  }
}

const state = getDefaultState();

const mutations = {
  setReportType(state, reportType) {
    state.reportType = reportType;
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  reportType: state => {
    return state.reportType
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}