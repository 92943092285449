import api from '@/Api'
import { cloneDeep } from 'lodash-es'

const getDefaultState = () => {
  return {
    netResultsData: null,
    selectedPartner: 'C5',
    partnerData: null,
    selectedReportFrequency: 'WEEKLY',
    selectedReportIndex: 0,
    error: null,
  }
}

const TAB_INDEX_LOOKUP = { 0: 'win_loss', 1: 'turnover'}

const state = getDefaultState()

const mutations = {
  setError(state, error) {
    state.error = error
  },
  setNetResultsData(state, netResultsData) {
    state.netResultsData = netResultsData
  },
  setSelectedPartner(state, accountName) {
    state.selectedPartner = accountName
  },
  clearError(state) {
    state.error = null
  },
  setPartnerData(state, partnerData) {
    state.partnerData = partnerData
  },
  setSelectedReportFrequency(state, selectedReportFrequency) {
    state.selectedReportFrequency = selectedReportFrequency
  },
  setSelectedReportIndex(state,value) {
    state.selectedReportIndex = value
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  reconciliationRows: state => {
    if (state.reconciliationDetail == null) return []
    return state.reconciliationDetail.rows
  },
  selectedReconciliationRow: state => {
    if (!state.netResultsData || !state.netResultsData) return null
    return state.netResultsData.rows.find(x => x.accountName === state.selectedPartner)
  },
  partnerData: state => {
    if (state.partnerData == null) return []
    let partners = cloneDeep(state.partnerData)
    partners.unshift({partnerId: 0, accountName: 'C5', isChecked: true})
    return partners 
  },
  netResultsDataRows: state => {
    return (state.netResultsData) ? state.netResultsData.rows : []
  },
  selectedAccountName: state => {
    return (state.netResultsData) ? state.netResultsData.accountName: ''
  },
  selectedCurrencyCode: state => {
    return (state.netResultsData) ? state.netResultsData.currencyCode: ''
  },
  selectedReportFrequency: state => {
    return state.selectedReportFrequency
  },
  selectedReportIndex: state => {
    return state.selectedReportIndex
  },
  requestQueryParams: (state) => (format, token) => {
    var params = {
      accountName: state.selectedPartner,
      period: state.selectedReportFrequency,
      type: TAB_INDEX_LOOKUP[state.selectedReportIndex],
      format: format,
    }
    if (token != null) {
      params['access_token'] = token
    }
    return params
  },
}

const actions = {
  async loadNetResults( {state, dispatch} ) {
    if (state.selectedPartner === 'C5') {
      dispatch('loadOrgNetResults')
    } else {
      dispatch('loadPartnerNetResults')
    }
  },
  async loadPartnerNetResults({ commit, getters }) {
    commit('setError', null)
    try {
      const params = getters.requestQueryParams('json')
      const response = await api.getPartnerNetResults(params)
      console.log("partner net results data loaded: ", response.data)
      commit('setNetResultsData', response.data)
    } catch (error) {
      console.log("failed to load net results data:", error)
      commit('setError', { 
        message: "Failed to load Net Results Data Data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },
  async loadOrgNetResults({ commit, getters}) {
    commit('setError', null)
    try {
      const params = getters.requestQueryParams('json')
      const response = await api.getOrgNetResults(params)
      console.log("partner net results data loaded: ", response.data)
      commit('setNetResultsData', response.data)
    } catch (error) {
      console.log("failed to load net results data:", error)
      commit('setError', { 
        message: "Failed to load Net Results Data Data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },
  async loadPartners( { commit } ) {
    try {
      const response = await api.getPartners()
      console.log('loaded partner data: ', response.data)
      commit('setPartnerData', response.data.rows)
    } catch (error) {
      console.log('failed to fetch partners')
      commit('setError', {
        message: "Failed to load Partner Data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  setSelectedPartner( {commit, dispatch}, selectedPartner) {
    commit('setSelectedPartner', selectedPartner)
    dispatch('loadNetResults')
  },
  setSelectedReportFrequency ( {commit, dispatch}, selectedReportFrequency) {
    commit('setSelectedReportFrequency', selectedReportFrequency)
    dispatch('loadNetResults')
  },
  setSelectedReportIndex({commit, dispatch}, value) {
    commit('setSelectedReportIndex', value)
    dispatch('loadNetResults')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}