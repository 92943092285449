import api from '@/Api'

const getDefaultState = () => {
  return {
    posStmtJobDetail: null,
    uuid: null,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {

  setPosStmtJobDetail(state, value) {
    state.posStmtJobDetail = value
  },
  setUuid(state, value) {
    state.uuid = value
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  posStmtJobDetail: state => {
    return state.posStmtJobDetail
  },
  posStmtJobDetailRows: state => {
    if (!state.posStmtJobDetail) return []
    return state.posStmtJobDetail.rows
  },
  error: state => {
    return state.error
  }
}

const actions = {
  async loadPosStmtJobDetail({ commit, state }) {
    commit('clearError');
    try {
      const response = await api.getPosStmtJobDetail(state.uuid)
      console.log("Customer Statement Jobs detail data loaded: ", response.data)
      commit('setPosStmtJobDetail', response.data)
    } catch(error) {
      console.error("loadPosStmtJobs:", error)
      commit('setError', { 
        message: "Failed to load customer statement job detail",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  setPosStmtJobUuid( {commit, dispatch}, uuid) {
    commit('setUuid', uuid)
    dispatch('loadPosStmtJobDetail')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
