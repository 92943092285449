import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    ledgerData: [],
    selectedDate: null,
    filterHideZeroBalance: true,
    filterActiveOnly: true,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setLedgerData(state, values) {
    state.ledgerData = values
  },
  setError(state,value) {
    state.error = value
  },
  clearError(state) {
    state.error = null
  },
  setSelectedDate(state, value) {
    state.selectedDate = value
  },
  setFilterHideZeroBalance(state, value) {
    state.filterHideZeroBalance = value
  },
  setFilterActiveOnly(state, value) {
    state.filterActiveOnly = value
  }
}

const getters = {
  ledgerData: state => {
    return state.ledgerData
  },
  ledgerDataRows: state => {
    return state.ledgerData?.rows
  },
  selectedDate: state => {
    return state.selectedDate
  },
  filterActiveOnly: state => {
    return state.filterActiveOnly
  },
  filterHideZeroBalance: state => {
    return state.filterHideZeroBalance
  }
}

const actions = {
  loadLedgers({ commit, state }) {
    commit('clearError')
    const params = {
      date: mixins.formatJsDate_localTZ(state.selectedDate),
      status: state.filterActiveOnly ? 'ACTIVE' : null,
      hideZeroBalance: state.filterHideZeroBalance,
    }
    api.getLedgers(params)
      .then( (response) => {
        console.log("ledgers loaded: ", response.data);
        commit('setLedgerData', response.data)
      }).catch(error => {
      console.error("failed to load ledgers:", error)
      commit('setError', { 
        message: "Failed to load ledgers",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    })
  },
  setSelectedDate({ commit, dispatch }, range) {
    commit('setSelectedDate', range)
    dispatch('loadLedgers')
  },
  setFilterHideZeroBalance({commit, dispatch}, value) {
    commit('setFilterHideZeroBalance', value)
    dispatch('loadLedgers')
  },
  setFilterActiveOnly({commit, dispatch}, value) {
    commit('setFilterActiveOnly', value)
    dispatch('loadLedgers')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}