<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar color="secondary" text-color="white" size="md">{{avatarName}}</CAvatar>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <!--
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>
      <CDropdownDivider />
      -->
      <router-link to="/logout" class="nav-link-outer">
      <CDropdownItem> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
      </router-link>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      itemsCount: 1,
    }
  },
  computed: {
    ...mapGetters( 'user', [
      'avatarName'
    ])
  },
}
</script>
