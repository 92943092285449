import api from '@/Api'
import { cloneDeep } from 'lodash-es'

const getDefaultState = () => {
  return {
    error: null,
    customerCodeId: null,
    customerAccountId: null,
    customerAccountNotes: { result: [], count: 0 },
    activeSort: ['createdAt,desc'],
  }
}

const state = getDefaultState()

const mutations = {
  setCustomerAccountId(state, customerAccountId) {
    state.customerAccountId = customerAccountId
  },
  setCustomerCodeId(state, customerCodeId) {
    state.customerCodeId = customerCodeId
  },
  setCustomerAccountNotes(state, notes) {
    state.customerAccountNotes = notes
  },
  setError(state, error) {
    state.error = error
  },
  setActiveSort(state, activeSort) {
    state.activeSort = activeSort
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  },
}

const getters = {
  customerAccountNotes: state => {
    return cloneDeep(state.customerAccountNotes)
  },
  error: state => {
    return state.error
  },
}

const actions = {
  async loadCustomerAccountNotes({commit, state}) {
    var params = new URLSearchParams() 
    for (let sort of state.activeSort) {
      params.append('sort', sort)
    }
    return api.getCustomerAccountNotes(state.customerCodeId, state.customerAccountId, params)
      .then(response => {
        if (response.status == 200) {
          console.log("customer account notes loaded: ", response.data)
          commit('setCustomerAccountNotes', response.data)
        }
      }).catch(error => {
        console.error("failed to load customer account notes:", error)
        commit('setError', { 
          message: "Failed to customer account notes",
          reason: error.message
        })
      })
  },
  async createCustomerAccountNote( {commit, state}, note ) {
    return api.addCustomerAccountNote(state.customerCodeId, state.customerAccountId, note)
    .then(response => {
      if (response.status == 200) {
        console.log("customer account note added: ", response.data);
      }
    })
    .catch(error => {
      console.error("failed to add customer account note:", error)
      commit('setError', { 
        message: "Failed to add customer account note",
        reason: error.message
      })
    })
  },
  async deleteCustomerAccountNote( {commit, state}, data) {
    return api.deleteCustomerAccountNote(state.customerCodeId, state.customerAccountId, data[0].customerNoteId)
      .then(response => {
        if (response.status == 200) {
          console.log("customer account note deleted: ", response.data)
        }
      })
      .catch(error => {
        console.error("failed to delete customer account note:", error)
        commit('setError', { 
          message: "Failed to delete customer account note",
          reason: error.message
        })
      })
  },
  async updateCustomerAccountNote( {commit, state}, note) {
    return api.updateCustomerAccountNote(state.customerCodeId, state.customerAccountId, note.customerNoteId, note)
      .then(response => {
        if (response.status == 200) {
          console.log("customer account note updated: ", response.data)
        }
      })
      .catch(error => {
        console.error("failed to update account customer note:", error)
        commit('setError', { 
          message: "Failed to update customer account note",
          reason: error.message
        })
      })
  },
  setCustomerAccountData({commit,dispatch}, data) {
    commit('setCustomerCodeId', data.customerCodeId)
    commit('setCustomerAccountId', data.customerAccountId)
    dispatch('loadCustomerAccountNotes')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}