import api from '@/Api'
import utils from '../../romaCommon.js'
import { cloneDeep } from 'lodash-es'

const getDefaultState = () => {
  return {
   transactionFees: [],
   error: null, 
  }
}

const state = getDefaultState();

const mutations = {
  setTransactionFees(state, value) {
    state.transactionFees = { result: value, count: value.length }
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  error: state => {
    return state.error
  },
  transactionFees: state => {
    return cloneDeep(state.transactionFees)
  }
}

const actions = {
  loadTransactionFees({ commit }) {
    return api.getTransactionFees().then(response => {
      console.log("Transaction Fees data loaded: ", response.data)
      commit('setTransactionFees', response.data.rows)
    }).catch(error => {
      console.error("getTransactionFees:", error)
      commit('setError', { 
        message: "Failed to load system currencies",
        reason: utils.formatApiErrorMessage(error)
      })
    })
  },
  addTransactionFee({commit}, fee) {
    return api.addTransactionFee(fee).then(response => {
      console.log("Add transaction fee success ", response.data)
    }).catch(error => {
      console.error("addTransactionFees:", error)
      commit('setError', { 
        message: "Failed to add transaction fee",
        reason: utils.formatApiErrorMessage(error)
      })
    })
  },
  updateTransactionFee({commit}, fee) {
    return api.updateTransactionFee(fee.transactionFeeConfigId, fee).then(response => {
      console.log("Update transaction fee success ", response.data)
    }).catch(error => {
      console.error("updateTransactionFees:", error)
      commit('setError', { 
        message: "Failed to update transaction fee",
        reason: utils.formatApiErrorMessage(error)
      })
    })
  },
  deleteTransactionFee({commit}, fee) {
    return api.deleteTransactionFee(fee).then(response => {
      console.log("Delete transaction fee success ", response.data)
    }).catch(error => {
      console.error("deleteTransactionFees:", error)
      commit('setError', { 
        message: "Failed to delete transaction fee",
        reason: utils.formatApiErrorMessage(error)
      })
    })
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
