import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    betData: [],
    filterMultiplierOnly: false,
    searchBySettledDate: true,
    selectedStartDate: new Date(),
    selectedEndDate: new Date(),
    selectedPage: 0,
    selectedPageSize: 250,
    selectedBetStatus: ['SETTLED'],
    selectedResultStatus: ['WIN','LOSE','DRAW'],
    error: null,
    exportSuccess: false,
  }
}

const state = getDefaultState();

const mutations = {
  setBetData(state, betData) {
    state.betData = betData
  },
  setError(state, error) {
    state.error = error
  },
  setExportSuccess(state, value) {
    state.exportSuccess = value
  },
  setSelectedStartDate(state, selectedStartDate) {
    state.selectedStartDate = selectedStartDate
  },
  setSelectedEndDate(state, selectedEndDate) {
    state.selectedEndDate = selectedEndDate
  },
  setSelectedPage(state, selectedPage) {
    state.selectedPage = selectedPage
  },
  setSelectedPageSize(state, selectedPageSize) {
    state.selectedPageSize = selectedPageSize
  },
  setSelectedBetStatus(state, selectedBetStatus) {
    state.selectedBetStatus = selectedBetStatus
  },
  setSelectedResultStatus(state, selectedResultStatus) {
    state.selectedResultStatus = selectedResultStatus
  },
  setFilterMultiplierOnly(state, filterMultiplierOnly) {
    state.filterMultiplierOnly = filterMultiplierOnly
  },
  setSearchBySettledDate(state, searchBySettledDate) {
    state.searchBySettledDate = searchBySettledDate
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState());
  }
}

const getters = {
  betDataFiltered: state => {
    return state.betData != null ? state.betData.rows : []
  },
  totalElements: state => {
    return state.betData != null ? state.betData.totalElements : 0
  },
  selectedStartDate: state => {
    return state.selectedStartDate
  },
  selectedEndDate: state => {
    return state.selectedEndDate
  },
  selectedPage: state => {
    return state.betData != null ? state.betData.page : 0
  },
  selectedPageSize: state => {
    return state.selectedPageSize
  },
  selectedBetStatus: state => {
    return state.selectedBetStatus
  },
  selectedResultStatus: state => {
    return state.selectedResultStatus
  },
  filterMultiplierOnly: state => {
    return state.filterMultiplierOnly
  },
  searchBySettledDate: state => {
    return state.searchBySettledDate
  },
  exportSuccess: state => {
    return state.exportSuccess
  },
  error: state => {
    return state.error
  }
}

const actions = {
  async loadBets({ commit, dispatch, state, rootState }) {
    commit('clearError')
    dispatch('setBetData', [])

    try {
      const params = { 
        fromDate: mixins.formatJsDate_localTZ(state.selectedStartDate),
        toDate: mixins.formatJsDate_localTZ(state.selectedEndDate),
        betStatus: state.selectedBetStatus.join(),
        resultStatus: state.selectedResultStatus.join(),
        multiplierOnly: state.filterMultiplierOnly,
        searchBySettledDate: state.searchBySettledDate,
        page: state.selectedPage,
        size: state.selectedPageSize,
      }
      const response = await api.getBets(rootState.account.account.accountName, params)
      console.log("bets loaded: ", response.data)
      commit('setBetData', response.data)
    } catch(error) {
      console.error("failed to load bets:", error)
      commit('setError', { 
        message: "Failed to load bets",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  setBetData({commit}, betData) {
    commit('setBetData', betData)
  },
  setSelectedPage( { commit, dispatch }, selectedPage) {
    commit('setSelectedPage', selectedPage)
    dispatch('loadBets')
  },
  setBetStatusChange( {commit, dispatch}, selected) {
    commit('setSelectedBetStatus', selected)
    dispatch('loadBets')
  },
  setResultStatusChange( {commit, dispatch}, selected) {
    commit('setSelectedResultStatus', selected)
    dispatch('loadBets')
  },
  setSelectedDateRange({ commit, dispatch }, dateRange) {
    commit('setSelectedStartDate', dateRange.startDate)
    commit('setSelectedEndDate', dateRange.endDate)
    dispatch('loadBets')
  },
  setFilterMultiplierOnly({commit, dispatch}, value) {
    commit('setFilterMultiplierOnly', value)
    dispatch('loadBets')
  },
  setSearchBySettledDate( {commit, dispatch}, value) {
    commit('setSearchBySettledDate', value)
    dispatch('loadBets')
  },
  async generateBetStatement({commit, state, rootState}, format) {
    try {
      const params = { 
        fromDate: mixins.formatJsDate_localTZ(state.selectedStartDate),
        toDate: mixins.formatJsDate_localTZ(state.selectedEndDate),
        betStatus: state.selectedBetStatus.join(),
        resultStatus: state.selectedResultStatus.join(),
        multiplierOnly: state.filterMultiplierOnly,
        searchBySettledDate: state.searchBySettledDate,
        format: format,
      }
      const response = await api.generateBetStatement(rootState.account.account.accountName, params)
      console.log("generate bet statement, returned ", response.status)

      if (response.status == 202) {
        commit('setExportSuccess', true)
      }
    } catch(error) {
      console.error("failed generate bet statement:", error)
      commit('setError', { 
        message: "Bet Statement Export request was not accepted",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  reset({commit}) {
    commit('resetState')
  },
  setExportSuccess({commit}, value) {
    commit('setExportSuccess', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}