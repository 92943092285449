export const romaLogo = [
   '113 44',
`
<g transform="translate(-33.066 -26.897)">
<path id="path53" transform="matrix(.11755 -.035809 .035809 .11755 15.058 23.246)" d="m253.48 270.9-90.68 20.818-63.369-68.122 27.311-88.94 90.68-20.818 63.369 68.122z" fill="none" stroke="#d9ac45" stroke-width="27.432" style="paint-order:stroke fill markers"/>
<use transform="translate(9.8631 17.172)" width="100%" height="100%" stroke="#c09046" xlink:href="#path53"/>
<g transform="matrix(.32883 0 0 .32883 -15.974 -11.554)" stroke-width="1.0016" style="shape-inside:url(#rect3436);white-space:pre" aria-label="roma">
 <g transform="translate(-30,-2)" fill="#fff" style="shape-inside:url(#rect5944)" aria-label="ROMA">
  <path d="m298.04 159.26v45.792h-11.156v-45.646q0-4.9583 4.2292-7.875 2.8438-1.8958 6.2708-1.8958h15.094q10.135 0 16.26 6.0521 5.1771 5.1771 5.1771 12.76v2.1146q0 9.4792-7.5104 14.729-2.8438 1.9688-6.2708 2.9896l15.021 16.771h-13.927l-13.854-15.677h-8.6042l5.9792-9.625h7.7292q6.8542 0 9.2604-4.7396 0.94792-1.9688 0.94792-4.4479v-2.1146q0-6.5625-5.9062-8.5312-1.9688-0.65625-4.3021-0.65625z"/>
  <path d="m369.79 205.78h-7.4375q-9.0417 0-15.385-6.4167-6.0521-6.125-6.0521-14.656v-14.729q0-8.8958 6.4167-15.094 6.3438-5.9792 15.021-5.9792h7.4375q9.0417 0 15.385 6.4167 6.0521 6.125 6.0521 14.656v14.729q0 8.8958-6.4167 15.094-6.3438 5.9792-15.021 5.9792zm-7.4375-9.625h7.4375q5.0312 0 8.0938-4.5208 2.1146-3.1354 2.1146-6.9271v-14.729q0-5.7604-4.1562-9.1875-2.6979-2.2604-6.0521-2.2604h-7.4375q-5.0313 0-8.0938 4.4479-2.1875 3.1354-2.1875 7v14.729q0 5.6875 4.0833 9.1146 2.8438 2.3333 6.1979 2.3333z"/>
  <path d="m451.02 161.08-12.396 35.875h-11.812l-12.396-35.875-4.2292 43.969h-11.958l5.1042-46.156q0.72917-6.1979 5.8333-8.6771 2.1146-0.94792 4.4479-0.94792 5.0312 0 7.6562 3.8646 0.65625 0.94792 1.0938 2.0417l10.354 30.333 10.354-30.333q1.6771-4.5208 6.4896-5.6146 1.1667-0.29167 2.2604-0.29167 5.25 0 8.2396 4.0833 1.6771 2.4063 2.0417 5.5417l5.1042 46.156h-11.958z"/>
  <path d="m511.18 197.69h-24.281l5.9792-9.625h14.948l-9.4792-27.198-15.458 44.188h-11.958l16.26-46.375q3.5729-9.6979 11.156-9.7708 7.5833 0.0729 11.083 9.7708l16.333 46.375h-11.958z"/>
 </g>
</g>
</g>
`,
]
