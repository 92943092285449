import axios from 'axios'
import { cacheAction } from 'vuex-cache'

const getDefaultState = () => {
  return {
    data: null,
  }
}

const state = getDefaultState()

const mutations = {
  setData(state, data) {
    state.data = data
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}


// only return an non-production environment label, if available
const getters = {
  environment: state => {
    if (state.data === null 
      || state.data.environment === null 
      || state.data.environment.trim().length === 0
      || state.data.environment.toUpperCase() === 'PROD') {
      return null
    }
    return state.data.environment.toUpperCase()
  }
}

const actions = {
  loadEnvironmentCached: cacheAction(({ cache, commit }) => {

    return cache.dispatch({type: 'loadEnvironment', timeout: 60000})
    .then(response => {
      commit('setData', response.data)
    })
    .catch(error => {
      console.log("failed to load environment data:", error)
    })
  }),
  loadEnvironment() {
    
    return axios.create({
      baseURL: '/',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-type': 'application/json'
      }
    }).get('environment.json' + '?t=' + new Date().getTime())
      .then(response => {
        return response
      })
      .catch(error => {
        console.log('error', error)
        return false
      })
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}