import api from '@/Api';

const createGraphTooltip = (r) => {
  if (r.parentAccountName.length == 0) {
    return `   
<div class="fw-bold google-visualization-tooltip-item">Credit Given</div> 
  <div class="google-visualization-tooltip-item">${r.accountName}</div> 
  <div class="h6 google-visualization-tooltip-item">${r.creditLimit.formatted}(${r.currencyCode})
`
  } else {
    const customerTemplate = r.customerId != null 
      ?  `
      <div class="google-visualization-tooltip-item">
        <div><span class="e-badge e-badge-info">Customer</span></div>
        <div><strong>ISNName:</strong> ${r.isnName}</div>
        <div><strong>Nickname:</strong> ${r.nickname}</div>
      </div>
      ` : ""

    return `
  ${customerTemplate}
  <div class="google-visualization-tooltip-item">
    <div><strong>Credit Given</strong></div> 
    <div>${r.parentAccountName} 
      <span class="e-icons e-arrow-right" style="position: relative; top: 1px;"></span> ${r.accountName}</div> 
    <div class="h6">${r.creditLimit.formatted} (${r.currencyCode})</div>
  </div>
`
  }
}

const getDefaultState = () => {
  return {
    convertToBase: false,
    creditLimitTree: [],
    selectedPartners: [],
    error: null, 
  }
}

const state = getDefaultState();

const mutations = {
  setCreditLimits(state, creditLimitTree) {
    state.creditLimitTree = creditLimitTree
  },
  setConvertToBase(state, convertToBase) {
    state.convertToBase = convertToBase
  },
  setSelectedPartners(state, selectedPartners) {
    state.selectedPartners = [...selectedPartners]
  },
  setError(state, error) {
    state.error = error;
  },
  clearError(state) {
    state.error = null;
  },
  resetState(state) {
    Object.assign(state,getDefaultState());
  }
}

const getters = {
  creditLimitTreeFiltered: state => {
    var results = [
      [ 
        { type: "string", label: "From", id: "from"},
        { type: "string", label: "To", id: "to"},
        { type: "number", label: "Credit Given", id: "credit_given"},
        { type: "string", role: 'tooltip', 'p': {'html': true }}
      ]]

      var f0 = state.creditLimitTree
        .filter( r => {
          const matched = state.selectedPartners.filter( p => {
            return r.accountName.startsWith(p)
          })
          return matched.length > 0
        })

      f0.forEach( r => {
          results.push([
            r.parentAccountName,
            r.accountName, 
            r.creditLimit.amount,
            createGraphTooltip(r)])
        })
    return results
  },
  partnerData: state => {
    var partners = []
    state.creditLimitTree.filter( r => r.accountLevel == 10).forEach( r => {
      partners.push(r.accountName)
    })
    return partners
  },
  convertToBase: state => {
    return state.convertToBase 
  },
  selectedPartners: state => {
    return state.selectedPartners
  }
}

const actions = {
  async loadCreditLimitTree({ commit, state }) {
    const params = {
      convertToBase: state.convertToBase
    }

    try {
      const response = await api.getCreditLimits(params);
      console.log("credit limits loaded: ", response.data);
      commit('setCreditLimits', response.data.rows);
    } catch (error) {
      console.log("failed to load credit limits:", error);
      commit('setError', { 
        message: "Failed to load credit limits data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },
  setConvertToBase( { commit, dispatch }, mode) {
    commit('setConvertToBase', mode)
    dispatch('loadCreditLimitTree')
  },
  setSelectedPartners( {commit}, partners) {
    commit('setSelectedPartners', partners)
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}