
import api from '@/Api'
import { cloneDeep } from 'lodash-es'
import { AccountStatementTypes } from '../../config/constants.js'

const getDefaultState = () => {
  return {
    accountName: '',
    account: '',
    activityDataSource: null,
    multiplierRows: [],
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setSelectedAccountName(state, accountName) {
    state.accountName = accountName
  },
  setAccount(state, account) {
    state.account = account
  },
  setActivityDataSource(state, data) {
    state.activityDataSource = data
  },
  setMultiplierRows(state, data) {
    state.multiplierRows = data
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  isPartnerAccount: state => {
    return (state.account && state.account.accountLevel == "P")
  },
  accountName: state => {
    return state.account && state.account.accountName
  },
  multiplierData: state => {
    return {
      result: state.multiplierRows,
      count: state.multiplierRows.length
    }
  },
  activityHistoryDataSource: state => {
    if (!state.activityDataSource) return null
    let data = cloneDeep(state.activityDataSource.rows)
    for (let i = 0; i < data.length; i++) {
      data[i].x = i
    }
    return data
  },
  activityDataSource: state => {
    return state.activityDataSource
  },
  hasMultiplier: state => {
    return (state.account) ? state.account.subAccounts.includes(AccountStatementTypes.X) : false
  },
  canCreateSubAccount: state => {
    if (!state.account) return false
    return !(state.account.isCustomer || state.account.isCustomerManaged) && state.account.accountStatus === 'Active' && state.account.accountLevel != 'MEM'
  }
}

const actions = {

  loadAccount({ state,commit }) {
    return api.getAccount(state.accountName)
      .then( response => {
        console.log("account loaded: ", response.data)
        commit('setAccount', response.data)
        return response
      }).catch(error => {
        console.log("failed to load account:", error)
        commit('setError', { 
          message: "Failed to load account: ",
          reason: error.message
        })
      })
  },
  reset({commit}) {
    commit('resetState')
  },
  async loadMultiplier( { state, commit }) {

    if (state.account.customerAccount) {
      try {
        const response = await api.getMultiplier(state.account.customerAccount.customerAccountId)
        console.log("multiplier loaded: ", response.data.rows)
        commit('setMultiplierRows', response.data.rows)
      } catch (error) {
        console.log("failed to load multiplier:", error)
        commit('setError', { 
          message: "Failed to load multiplier: ",
          reason: error.message
        });
      }
    }
  },
  createMultiplier({commit}, data) {
    return api.addMultiplier(state.account.customerAccount.customerAccountId, data)
      .then(response => {
        if (response.status == 200) {
          console.log("created multiplier: ", response.data)
        }
      }).catch(error => {
        console.error("failed to create multiplier:", error)
        commit('setError', { 
          message: "Failed to create multiplier config",
          reason: error.message
        })
      })
  },
  loadAccountActivity({commit}) {
    api.getAccountActivitySummary(state.account.accountName, {yearly: true})
      .then( response => {
        commit('setActivityDataSource', response.data)
      })
  },
  async updateCustomerAccount({ commit }, data) {
    return api.updateCustomerAccount(data.customerCodeId, data.customerAccountId, data.payload)
      .then(response => {
        console.log("customer account updated: ", response.data)
      }).catch (error =>  {
        console.log("failed to update customer account:", error)
        commit('setError', { 
          message: "Failed to update customer account: ",
          reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
