
import api from '@/Api';

const getDefaultState = () => {
  return {
    msAccountBalances: [],
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setMsAccountBalances(state, msAccountBalances) {
    state.msAccountBalances = msAccountBalances
  },
  setError(state, error) {
    state.error = error
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  msAccountBalances: state => {
    return state.msAccountBalances ? state.msAccountBalances.rows : [];
  }
}

const actions = {
  async loadMsAccountBalances({ commit }, accountName) {

    try {
      const response = await api.getMsAccountBalances(accountName);
      console.log("Ms Account Balance data loaded: ", response.data);
      commit('setMsAccountBalances', response.data)
    } catch (error) {
      console.log("failed to load Ms Account Balances:", error);
      commit('setError', { 
        message: "Failed to load Manager Site Account Balances: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}