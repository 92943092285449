import api from '@/Api'

const getDefaultState = () => {
  return {
    reconciliationDetail: null,
    error: null,
  }
}

const state = getDefaultState()

const mutations = {
  setError(state, error) {
    state.error = error
  },
  setReconciliationDetail(state, reconciliationDetail) {
    state.reconciliationDetail = reconciliationDetail
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  reconciliationRows: state => {
    if (state.reconciliationDetail == null) return []
    return state.reconciliationDetail.rows
  },
}

const actions = {
  async loadDailyReconciliationDetail({ commit }, date) {
    commit('setError', null)
    try {
      const params = { 
        date: date
      }
      const response = await api.getDailyReconciliationDetail(params)
      console.log("daily reconciliation detail data loaded: ", response.data)
      commit('setReconciliationDetail', response.data)
    } catch (error) {
      console.log("failed to load Daily Reconciliation Detail data:", error)
      commit('setError', { 
        message: "Failed to load Daily Reconciliation Detail Data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },

  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}