import api from '@/Api'
import { cacheAction } from 'vuex-cache'

const getDefaultState = () => {
  return {
    accountNav: null,
    filterActiveOnly: true,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setAccountNav(state, rows) {
    state.accountNav = rows
  },
  setFilterActiveOnly(state, value) {
    state.filterActiveOnly = value
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  accountNav: state => {
    return (state.accountNav != null) ? state.accountNav : []
  },
  filterActiveOnly: state => {
    return state.filterActiveOnly
  }
}

const actions = {
  loadAccountNavCached: cacheAction(({ cache, commit, state }) => {

    var params = new URLSearchParams( {
      size: 5000,
      customerOnly: false,
      includeManaged: false,
    })
    params.append('status','ACTIVE')
    if (!state.filterActiveOnly) {
      params.append('status','INACTIVE')
      params.append('status', 'CLOSED')
      params.append('status','SUSPENDED')
    }

    return cache.dispatch('requestAccountNav', params, { timeout: 600000 })
    
      .then(response => {
        console.log("account nav data loaded: ", response.data)
        commit('setAccountNav', response.data.rows)
      })
      .catch(error => {
        console.log("failed to load accounting status data:", error)
      })
  }),
  requestAccountNav(context, props) {
    return api.getAccountsTree(props)
  },
  setFilterActiveOnly({ commit, dispatch }, payload) {
    commit('setFilterActiveOnly', payload)
    dispatch('loadAccountNavCached')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}