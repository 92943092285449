import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    events: [],
    upcomingEvents: [],
    wdwnData: [],
    wdwnRawData: [],
    error: null,
    selectedSport: null,
    selectedLeague: null,
    selectedEventId: null,
    selectedScoreType: 'FT',
    selectedCurrencyCode: 'BASE',
    selectedRunningBall: true,
    selectedMarketAH: true,
    selectedMarketOU: true,
    selectedMarket1x2: true,

    toggleEventSelectType: 'upcoming',
    //selectedLimitByOU: true,
  }
}

const state = getDefaultState();

const mutations = {  
  setEvents(state, events) {
    state.events = events
  },
  setUpcomingEvents(state, events) {
    for (let i in events) {
      events[i]['eventDate'] = mixins.formatIsoDate_romaTZ(events[i]['eventDateTime'])
    }
    state.upcomingEvents = events
  },
  setWDWNData(state, data) {
    state.wdwnData = data
  },
  setWDWNRawData(state, data) {
    state.wdwnRawData = data
  },
  setSelectedSport(state, sport) {
    state.selectedSport = sport
  },
  setSelectedLeague(state, league) {
    state.selectedLeague = league
  },
  setSelectedEventId(state, eventId) {
    state.selectedEventId = eventId
  },
  setSelectedCurrencyCode(state, code) {
    state.selectedCurrencyCode = code
  },
  setSelectedScoreType(state, value) {
    state.selectedScoreType = value
  },
  setSelectedRunningBall(state, value) {
    state.selectedRunningBall = value
  },
  setSelectedMarketAH(state, value) {
    state.selectedMarketAH = value
  },
  setSelectedMarketOU(state, value) {
    state.selectedMarketOU = value
  },
  setSelectedMarket1x2(state, value) {
    state.selectedMarket1x2 = value
  },
  setToggleEventSelectType(state, value) {
    state.toggleEventSelectType = value
  },
  //setSelectedLimitByOU(state, value) {
  //  state.selectedLimitByOU = value
  //},
  clearError(state) {
    state.error = null
  },
  setError(state, error) {
    state.error = error
  },
  clearForSportSelection(state) {
    state.selectedEventId = null
    state.selectedLeague = null
  },
  clearForLeagueSelection(state) {
    state.selectedEventId = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}



const getters = {
  sports: state => {
    return Object.keys(state.events)
  },
  leagues: state => {
    if (state.selectedSport == null) return []
    return Object.keys(state.events[state.selectedSport])
  },
  events: state => { 
    if (!state.selectedSport || !state.selectedLeague) return []
    let newArray = [...state.events[state.selectedSport][state.selectedLeague]]
    return newArray.sort(mixins.dateTimePropertySorter('eventDateTime'))
  },
  upcomingEvents: state => {
    return state.upcomingEvents
  },
  wdwnData: state => {
    return state.wdwnData.spreadsheet
  },
  wdwnRawData: state => {
    return state.wdwnRawData
  },
  betAHCount: state => {
    return state.wdwnData.betAHCount || 0
  },
  betOUCount: state => {
    return state.wdwnData.betOUCount || 0
  },
  bet1x2Count: state => {
    return state.wdwnData.bet1x2Count || 0
  },
  inRunningCount: state => {
    return state.wdwnData.inRunningCount || 0
  },
  lastOUHandicap: state => {
    return state.wdwnData.lastOUHandicap || 0
  },
  selectedSport: state => {
    return state.selectedSport
  },
  selectedLeague: state => {
    return state.selectedLeague
  },
  selectedEventId: state => {
    return state.selectedEventId
  },
  selectedCurrencyCode: state => {
    return state.selectedCurrencyCode
  },
  selectedScoreType: state => {
    return state.selectedScoreType
  },
  selectedRunningBall: state => {
    return state.selectedRunningBall
  },
  selectedMarketAH: state => {
    return state.selectedMarketAH
  },
  selectedMarketOU: state => {
    return state.selectedMarketOU
  },
  selectedMarket1x2: state => {
    return state.selectedMarket1x2
  },
  toggleEventSelectType: state => {
    return state.toggleEventSelectType
  },
  //selectedLimitByOU: state => {
  //  return state.selectedLimitByOU
  //},
  error: state => {
    return state.error
  },
}

const actions = {
  async loadEvents({ commit }) {
    try {
      const response = await api.getEventData()
      console.log("Event data loaded: ", response.data)
      commit('setEvents', response.data)
    } catch(error) {
      console.error("error loading sports:", error)
      commit('setError', { 
        message: "Failed to load sports",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  async loadUpcomingEvents({ commit }) {
    try {
      const response = await api.getUpcomingBetEvents()
      console.log("Upcoming event data loaded: ", response.data)
      commit('setUpcomingEvents', response.data.rows)
    } catch(error) {
      console.error("error loading upcoming events:", error)
      commit('setError', { 
        message: "Failed to load upcoming events",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  async loadWDWN({ commit,state }) {

    if (!state.selectedEventId) {
      return
    }

    try {
      let markets = []
      if (state.selectedMarketAH) {
        markets.push('AH')
      }
      if (state.selectedMarketOU) {
        markets.push('OU')
      }
      if (state.selectedMarket1x2) {
        markets.push('1x2')
      }

      const params = {
        eventId: state.selectedEventId,
        scoreType: state.selectedScoreType,
        currencyCode: state.selectedCurrencyCode,
        convertToBase: state.selectedCurrencyCode == 'BASE',
        runningBall: state.selectedRunningBall,
        //limitByOU: state.selectedLimitByOU,
        markets: markets.join()
      }
      console.log('params', params)
      const response = await api.getWDWN(params)
      console.log("WDWN data loaded: ", response.data)
      commit('setWDWNData', response.data)
      commit('setWDWNRawData', response.data.rawData)
    } catch(error) {
      console.error("error loading WDWN data", error)
      commit('setError', { 
        message: "Failed to load WDWN data",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  setSelectedSport({commit}, payload) {
    commit('setSelectedSport', payload)
    commit('clearForSportSelection')
  },
  setSelectedLeague({commit}, payload) {
    commit('setSelectedLeague', payload)
    commit('clearForLeagueSelection')
  },
  setToggleEventSelectType({commit}, payload) {
    commit('setToggleEventSelectType', payload)
  },
  async setSelectedScoreType({commit,dispatch}, payload) {
    commit('setSelectedScoreType', payload)
    await dispatch('loadWDWN')
  },
  async setSelectedEvent({commit,dispatch}, payload) {
    commit('setSelectedSport', payload.sport)
    commit('setSelectedLeague', payload.league)
    commit('setSelectedEventId', payload.eventId)
    await dispatch('loadWDWN')
  },
  async setSelectedCurrencyCode({commit, dispatch}, payload) {
    commit('setSelectedCurrencyCode', payload)
    await dispatch('loadWDWN')
  },
  async setSelectedRunningBall({commit,dispatch}, payload) {
    commit('setSelectedRunningBall', payload)
    await dispatch('loadWDWN')
  },
  async setSelectedMarketAH({commit,dispatch}, payload) {
    commit('setSelectedMarketAH', payload)
    await dispatch('loadWDWN')
  },
  async setSelectedMarketOU({commit,dispatch}, payload) {
    commit('setSelectedMarketOU', payload)
    await dispatch('loadWDWN')
  },
  async setSelectedMarket1x2({commit,dispatch}, payload) {
    commit('setSelectedMarket1x2', payload)
    await dispatch('loadWDWN')
  },
  //async setSelectedLimitByOU({commit,dispatch}, payload) {
  //  commit('setSelectedLimitByOU', payload)
  //  await dispatch('loadWDWN')
  //},
  clearError({commit}) {
    commit('clearError')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}