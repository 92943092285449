import api from '@/Api'
import { cloneDeep } from 'lodash-es'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    dailySummaryData: null,
    selectedDate: mixins.getJsDateNow_romaTZ(),
    filterActiveAccount: true,
    filterPruneNonActive: true, 
    error: null,
  }
}

const state = getDefaultState()

const mutations = {
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  setFilterActiveAccount(state, value) {
    state.filterActiveAccount = value
  },
  setFilterPruneNonActive(state,value) {
    state.filterPruneNonActive = value
  },
  setDailySummaryData(state, dailySummaryData) {
    state.dailySummaryData = dailySummaryData
  },
  setSelectedDate(state, selectedDate) {
    state.selectedDate = selectedDate
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

function markParentNoDelete(currentElement, elements) {
  const parentIndex = elements.findIndex(e => e.accountId === currentElement.parentId)
  if (parentIndex > -1) {
    const parent = elements[parentIndex]
    elements[parentIndex]['noDelete'] = true
    markParentNoDelete(parent, elements)
  }
}


function pruneParent(currentElement, elements) {
  const parentIndex = elements.findIndex(e => e.accountId === currentElement.parentId)
  if (parentIndex > -1) {
    const parent = elements[parentIndex]
    if (parent.noDelete == null && parent.stake.amount == 0 && parent.winLoss.amount == 0 && parent.transfer.amount == 0) {
      elements.splice(parentIndex, 1)
      pruneParent(parent, elements)
    }
  }
}

const getters = {
  dailySummaryDataFiltered: state => {

    let filteredAccounts = []
    if (!state.dailySummaryData) return []

    for (let act of state.dailySummaryData.rows) {
      if (state.filterActiveAccount) {
        if (act.status !== 'Active' && act.stake.amount == 0 && act.winLoss.amount == 0 
            && act.transfer.amount == 0 && act.memberFee.amount == 0) {
          continue;
        }
      }
      filteredAccounts.push(cloneDeep(act));
    }

    // prune downstream accounts with no activity, start with member level accounts and traverse and remove upstream
    let copyFilteredAccounts = Array.from(filteredAccounts)

    // find children accounts
    if (state.filterPruneNonActive) {
      let childAccounts = copyFilteredAccounts.filter( act => {
          // does this record appear as a parent of any other record
          let idx = filteredAccounts.findIndex(e => (e.parentId != null && e.parentId == act.accountId));
          return idx == -1;
        });
      
      // find children that will not be removed and mark parents as don't delete
      childAccounts
        .forEach(act => {
          if (act.stake.amount != 0 || act.winLoss.amount !=0 || act.transfer.amount != 0 || act.memberFee.amount != 0) {
            act['noDelete'] = true
            markParentNoDelete(act, filteredAccounts)
          }
        })

      childAccounts
        .forEach(act => {
          if (act.stake.amount == 0 && act.winLoss.amount == 0 && act.transfer.amount == 0 && act.memberFee.amount == 0 ) {
            
            const idx = filteredAccounts.indexOf(act);
            if (idx > -1) {
              filteredAccounts.splice(idx, 1);
              // find parent and recursively prune if no activity
              pruneParent(act, filteredAccounts);
            }
          } 
        });
    }
    return filteredAccounts
  },
}

const actions = {
  async loadDailySummary({ commit,state }) {
    commit('setError', null)
    try {
      const params = { 
        date: mixins.formatJsDate_localTZ(state.selectedDate),
      }
      const response = await api.getDailySummary(params)
      console.log("daily summary data loaded: ", response.data)
      commit('setDailySummaryData', response.data)
    } catch (error) {
      console.log("failed to load Daily Summary data:", error)
      commit('setError', { 
        message: "Failed to load Daily Summary Data: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      });
    }
  },
  setSelectedDate({ commit, dispatch }, date) {
    commit('setSelectedDate', date)
    dispatch('loadDailySummary')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}