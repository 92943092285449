import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    partnerBalances: [],
    selectedCalendarRange: null,
    convertToBase: false,
    systemView: false,
    groupByCurrency: false,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setPartnerBalances(state, partnerBalances) {
    state.partnerBalances = partnerBalances
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  setConvertToBase(state, convertToBase) {
    state.convertToBase = convertToBase
  },
  setSelectedCalendarRange(state, selectedCalendarRange) {
    state.selectedCalendarRange = selectedCalendarRange
  },
  setSystemView(state, systemView) {
    state.systemView = systemView
  },
  setGroupByCurrency(state, groupByCurrency) {
    state.groupByCurrency = groupByCurrency
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  partnerBalancesFiltered: state => {
    if (!state.partnerBalances) return []
    return state.partnerBalances
  },
  selectedCalendarRange: state => {
    return state.selectedCalendarRange
  },
  systemView: state => {
    return state.systemView
  },
  groupByCurrency: state => {
    return state.groupByCurrency
  },
  requestQueryParams: (state) => (format) => {
    return {
      fromDate: mixins.formatJsDate_localTZ(state.selectedCalendarRange[0]),
      toDate: mixins.formatJsDate_localTZ(state.selectedCalendarRange[1]),
      convertToBase: state.convertToBase,
      systemView: state.systemView,
      groupByCurrency: state.groupByCurrency,
      format: format,
    }
  },
  convertToBase: state => {
    return state.convertToBase
  }
}

const actions = {
  async loadPartnerBalances({ commit, getters }) {
    commit('clearError')
    commit('setPartnerBalances', [])
    try {

      const response = await api.getPartnerBalances(getters.requestQueryParams())
      console.log("Partner Balance data loaded: ", response.data)
      commit('setPartnerBalances', response.data.rows)
    } catch(error) {
      console.error("getPartnerBalances:", error)
      commit('setError', { 
        message: "Failed to load Partner Balances",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  setSelectedCalendarRange({ commit, dispatch }, range) {
    commit('setSelectedCalendarRange', range)
    dispatch('loadPartnerBalances')
  },
  resetState({commit}) {
    commit('resetState')
  },
  setConvertToBase( {commit, dispatch }, convertToBase) {
    commit('setConvertToBase', convertToBase)
    dispatch('loadPartnerBalances')
  },
  setSystemView( {commit, dispatch }, value) {
    commit('setSystemView', value)
    dispatch('loadPartnerBalances')
  },
  setGroupByCurrency( {commit, dispatch }, value) {
    commit('setGroupByCurrency', value)
    dispatch('loadPartnerBalances')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
