import { createStore, createLogger } from 'vuex'
import createCache from 'vuex-cache'
import account from './modules/account'
import accountingState from './modules/accountingState'
import accountBets from './modules/accountBets'
import accountNav from './modules/accountNav'
import accounts from './modules/accounts'
import accountCashBalance from './modules/accountCashBalance'
import accountActivity from './modules/accountActivity'
import creditGiven from './modules/creditGiven'
import creditGivenTable from './modules/creditGivenTable'
import creditGivenGraph from './modules/creditGivenGraph'
import c5ppr from './modules/c5ppr'
import customer from './modules/customer'
import customers from './modules/customers'
import customerAccountNotes from './modules/customerAccountNotes'
import customerBalances from './modules/customerBalances'
import currency from './modules/currency'
import dailyReconciliationCB from './modules/dailyReconciliationCB'
import dailyReconciliationCL from './modules/dailyReconciliationCL'
import dashboard from './modules/dashboard'
import environment from './modules/environment'
import journalEntriesCB from './modules/journalEntriesCB'
import journalEntriesCL from './modules/journalEntriesCL'
import ledger from './modules/ledger'
import ledgerPosition from './modules/ledgerPosition'
import ledgers from './modules/ledgers'
import msBalance from './modules/msBalance'

import partner from './modules/partner'
import partnerActivity from './modules/partnerActivity'
import positions from './modules/positions'
import posStmtJobs from './modules/posStmtJobs'
import posStmtJobDetail from './modules/posStmtJobDetail'
import nav from './modules/nav'
import sevenDayActivity from './modules/sevenDayActivity'
import systemLive from './modules/systemLive'
import transactions from './modules/transactions'
import transactionFee from './modules/transactionFee'
import user from './modules/user'
import wdwn from './modules/wdwn'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    account,
    accountingState,
    accountBets,
    accountNav,
    accounts,
    accountCashBalance,
    c5ppr,
    creditGiven,
    creditGivenTable,
    creditGivenGraph,
    currency,
    customer,
    customerAccountNotes,
    customers,
    customerBalances,
    accountActivity,
    dailyReconciliationCB,
    dailyReconciliationCL,
    dashboard,
    environment,
    journalEntriesCB,
    journalEntriesCL,
    msBalance,
    ledger,
    ledgerPosition,
    ledgers,
    systemLive,
    nav,
    partner,
    partnerActivity,
    positions,
    posStmtJobs,
    posStmtJobDetail,
    sevenDayActivity,
    transactions,
    transactionFee,
    user,
    wdwn,
  },
  strict: debug,
  plugins: debug ? [createLogger(), createCache()] : [createCache()]
})
