import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import Api from './Api'
import Keycloak from "keycloak-js"
import VueLogger from 'vuejs3-logger'
import { abilitiesPlugin } from '@casl/vue'
import defineAbilityFor from './config/ability'
import { registerLicense } from '@syncfusion/ej2-base'
import VersionUpdateUtility from './components/VersionUpdateUtility.js'

registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVGfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9SdEViUHtddXRSQGJY')

const isProduction = process.env.NODE_ENV === 'production'

const logOptions = {
  isEnabled: true,
  //logLevel: isProduction ? 'error' : 'debug',
  logLevel: 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: false,
  separator: '|',
  showConsoleColors: true
}

let keycloak = new Keycloak('/keycloak.json')

const APP_HASH = '{{POST_BUILD_HASH_PLACEHOLDER}}'
let displayedVersionUpdateDlg = false

Api.init({
  baseURL: '/api',
  timeout: 20000
  });

keycloak.init({ onLoad: "login-required", checkLoginIframe: false }).then((auth) => {

  if (!auth) {
    window.location.reload();
  } else {
    console.log("Authenticated");

    localStorage.setItem("token", keycloak.token);
    store.commit('user/setToken', keycloak.token);
    const ability = defineAbilityFor(store.getters['user/roles']);

    const app = createApp(App)
    app.use(store)
    app.use(router)
    app.use(CoreuiVue)
    app.use(VueLogger, logOptions)
    app.use(abilitiesPlugin, ability, {
      useGlobalProperties: true
    })
    app.provide('icons', icons)
    app.component('CIcon', CIcon)
    app.mount('#app')

    app.config.globalProperties.$keycloak = keycloak;

    setInterval(() => {
      keycloak.updateToken(60).then((refreshed) => {
        if (refreshed) {
          console.log('Token refreshed: ' + refreshed)
          localStorage.setItem('token', keycloak.token)
        } else {
          console.log('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).catch (() => {
        console.log('Failed to refresh token')
      })
    }, 10000)

    if (isProduction) {
      setInterval(() => {
        VersionUpdateUtility.checkNewVersion(APP_HASH).then((newVersion) => {

          console.log('checkVersion() is new? ', newVersion)
          if (!displayedVersionUpdateDlg && newVersion) {
            displayedVersionUpdateDlg = true
            setTimeout(VersionUpdateUtility.createPopup, 2 * 60 * 1000)
          }
        })
        
      }, 1 * 60 * 1000)
    }
  }
}).catch((error) => {
  console.log("Authentication failed", error);
});
