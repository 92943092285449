
import api from '@/Api'

const getDefaultState = () => {
  return {
    error: null,
    customers: [],
    customerSummary: null,

    filterActive: true,
    filterClosed: false,
    filterInactive: false,
  }
}

const state = getDefaultState()

const mutations = {
  setCustomers(state, customers) {
    state.customers = customers;
  },
  setCustomerSummary(state, customerSummary) {
    state.customerSummary = customerSummary
  },
  setFilterActive(state, value) {
    state.filterActive = value
  },
  setFilterClosed(state, value) {
    state.filterClosed = value
  },
  setError(state, error) {
    state.error = error;
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  customers: state => {
    return state.customers
  },
  customerSummary: state => {
    return state.customerSummary
  },
  filterActive: state => {
    return state.filterActive
  },
  filterInactive: state => {
    return state.filterInactive
  },
  filterClosed: state => {
    return state.filterClosed
  },
  error: state => {
    return state.error
  },
}

const actions = {
  async loadCustomerSummary({commit}) {
    try {
      const response = await api.getCustomerSummary()
      console.log("customer summary: ", response.data)
      commit('setCustomerSummary', response.data)
    } catch (error) {
      console.log("failed to load customer summary:", error)
      commit('setError', { 
        message: "Failed to load customer summary: ",
        reason: this.formatApiErrorMessage(error)
      });
    }
  },
  async loadCustomers({ commit,state }) {

    const states = [
      ...(state.filterActive ? ['ACTIVE'] : []),
      ...(state.filterClosed ? ['CLOSED'] : []),
      ...(state.filterInactive ? ['INACTIVE'] : []),
    ]
    const params = {
      filter: states.join(",")
    }

    try {
      const response = await api.getCustomers(params)
      console.log("customers loaded: ", response.data)
      commit('setCustomers', response.data.rows)
    } catch (error) {
      console.log("failed to load customers:", error)
      commit('setError', { 
        message: "Failed to load customers: ",
        reason: this.formatApiErrorMessage(error)
      });
    }
  },
  async loadActiveCustomers({ commit }) {

    const params = { filter: 'ACTIVE' }

    try {
      const response = await api.getCustomers(params)
      console.log("customers loaded: ", response.data)
      commit('setCustomers', response.data.rows)
    } catch (error) {
      console.log("failed to load customers:", error)
      commit('setError', { 
        message: "Failed to load customers: ",
        reason: this.formatApiErrorMessage(error)
      });
    }
  },
  async addCustomer({ commit,dispatch}, customer) {
    try {
      const response = await api.addCustomer(customer)
      console.log("customer created: ", response.data)
      dispatch('loadCustomers')
    } catch (error) {
      console.log("failed to create customer:", error)
      commit('setError', { 
        message: "Failed to create customer: ",
        reason: this.formatApiErrorMessage(error)
      })
    }
  },
  updateCustTransFees({ commit, dispatch}, payload) {
    return api.updateCustomerAutoTransFee(payload)
      .then(response => {
        console.log("updated customer auto trans fees: ", response.data)
        dispatch('loadCustomers')
        return response
      }).catch(error => {
      console.log("failed to create customer:", error)
      commit('setError', { 
        message: "Failed to create customer: ",
        reason: this.formatApiErrorMessage(error)
      })
      })
  },
  setFilterActive({commit,dispatch}, value) {
    commit('setFilterActive', value)
    dispatch('loadCustomers')
    dispatch('loadCustomerSummary')
  },
  setFilterClosed({commit, dispatch}, value) {
    commit('setFilterClosed', value)
    dispatch('loadCustomers')
    dispatch('loadCustomerSummary')
  },
  reset({commit}) {
    commit('resetState')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}