<template>
  <CHeader position="sticky" class="mb-4 pb-0">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('nav/toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="romaLogoBlk" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <router-link to="/dashboard" class="nav-link-outer">
            <CNavLink> Dashboard </CNavLink>
          </router-link>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav v-if="environment" class="d-none d-md-flex me-auto">
        <CAlert class="p-1" color="danger">
          <span class="h5">{{environment}} ENVIRONMENT</span>
        </CAlert>
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <CRow>
          <ejs-autocomplete id="searchbox" ref="searchbox"
              width="200"
              popupWidth="300"
              placeholder="search"
              :query="query"
              :fields="queryFields"
              :dataSource="searchResults" 
              :highlight="true"
              :select="onSelectSearch"
              :itemTemplate="'searchTemplate'">
              <template v-slot:searchTemplate="{data}">
                <CRow>
                  <CCol :class="['px-1 pt-2 pb-0',getSearchResultColor(data.type)]" sm="auto">
                    <span :class="['e-icons', getSearchResultIcon(data.type)]" style="font-size: 27px;" />
                  </CCol>
                  <CCol class="m-0 p-1" style="text-indent: 0px">
                    <CRow v-if="data.type === 'ACCOUNT'" class="m-0 p-1" style="font-size: 10px; line-height: 12px; flex-wrap: nowrap;">
                      <span class="px-0" style="width: auto; "><strong>Account:</strong></span>
                      <span class="px-1" style="width: auto; ">{{data.typeKey}}</span>
                    </CRow>
                    <CRow v-else class="m-0 p-1" style="font-size: 10px; line-height: 12px; flex-wrap: nowrap;">
                      <span class="px-0" style="width: auto; "><strong>Customer:</strong></span> 
                      <span class="px-1" style="width: auto; ">{{data.typeKey}}</span> 
                    </CRow>
                    
                    <CRow class="m-0 p-1" style="font-size: 10px; line-height: 12px; flex-wrap: nowrap;">
                      <span class="px-0" style="width: auto; "><strong>{{data.contextKey}}:</strong></span>
                      <span class="px-1" style="width: auto; ">{{data.contextValue}}</span>
                    </CRow>
                  </CCol>
                </CRow>
              </template>
          </ejs-autocomplete>
          </CRow>
          <CRow class='justify-content-end'>
            <CCol sm='auto pe-0'>
            <RomaServerTimePanel />
            </CCol>
          </CRow>
        </CNavItem>
        
        
        <!-- CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem -->
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import api from '@/Api'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import RomaServerTimePanel from './RomaServerTimePanel'
import { mapActions, mapGetters } from 'vuex'
import { romaLogoBlk } from '@/assets/brand/roma-logo-blk'
import { Query, DataManager, CustomDataAdaptor } from '@syncfusion/ej2-data'
import { AutoCompleteComponent } from '@syncfusion/ej2-vue-dropdowns'


export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    RomaServerTimePanel,

    'ejs-autocomplete': AutoCompleteComponent,
  },
  data() {
    var remoteData = new DataManager({
      adaptor: new CustomDataAdaptor({
        getData: function(option) {
          const query = JSON.parse(option.data)
          api.getSearchResults({keyword: query.where[0].value.trim()}).then(result => {
            let request = option
            option.httpRequest = result.request
            console.log(result.data)
            option.onSuccess(result.data, request)
          }).catch(error => {
            let request = option
            option.httpRequest = error.request
            option.onFailure(request)
          })
        }
      }),
      
    })

    return {
      searchResults: remoteData,
      query: new Query(),
      queryFields: { value: 'typeKey'},
    }
  },
  setup() {
    return {
      romaLogoBlk,
    }
  },
  mounted() {
    this.loadEnvironmentCached()
  },
  computed: {
    ...mapGetters( 'environment', [
      'isProduction',
      'environment'
    ]),
  },
  methods: {
    ...mapActions('environment',[
      'loadEnvironmentCached',
    ]),
    getSearchResultIcon(type) {
      if (type === 'ACCOUNT') {
        return "e-description"
      } else if (type === 'CUSTOMER') {
        return "e-user"
      }
    },
    getSearchResultColor(type) {
      if (type === 'ACCOUNT') {
        return "bg-success"
      } else if (type === 'CUSTOMER') {
        return "bg-warning"
      }
    },
    onSelectSearch(e) {
      console.log(e)
      if (e.itemData.type === 'ACCOUNT') {
        this.$router.push({ name: 'account-details', params: { accountName: e.itemData.typeKey }})
      } else if (e.itemData.type === 'CUSTOMER') {
        this.$router.push({ name: 'customer', params: { customerCodeId: e.itemData.typeKey }})
      }
      setTimeout( () => this.$refs.searchbox.clear(), 500)
      
    },
    
  }
}
</script>

<style>
  .nav-link-outer {
    color: var(--cui-sidebar-nav-link-color, rgba(255, 255, 255, 0.6));
    text-decoration: none;
    white-space: nowrap;
    background: var(--cui-sidebar-nav-link-bg, transparent);
  }
  #searchbox_popup .e-highlight {
    background: yellow;
    padding: 0px;
    margin: 0px;
    text-indent: 0px;
  }
</style>
