<template>
    <span class="server-time">
    {{timestr}}
    </span>
</template>
<script>

import api from '../Api'

export default {
  name: 'romaServerTime',
  components: {
  },
  data: function() {
    return {
      timestr: '',
      interval: null,
    }
  },
  mounted() {
    this.loadServerTime()
    this.interval = setInterval(() => {
      this.loadServerTime()
    }, 60000)
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    loadServerTime() {
      api.getSystemTime()
        .then(response => {
          this.timestr = response.data.serverDateTime
        })
        .catch(() =>  {
          this.timestr = 'unavailable'
        })
    }
  }
}
</script>
<style scoped>

  .server-time {
    font-size: x-small;
    font-weight: 500;
    color: var(--cui-gray-500);
  }
</style>