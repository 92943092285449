import api from '@/Api'

const getDefaultState = () => {
  return {
    posStmtJobsData: null,
    error: null,
    selectedPage: 0,
    pageSize: 100,
  }
}

const state = getDefaultState();

const mutations = {

  setPosStmtJobs(state, value) {
    state.posStmtJobsData = value
  },
  setSelectedPage(state, value) {
    state.selectedPage = value
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  pageSize: state => {
    return state.pageSize
  },
  selectedPage: state => {
    return state.selectedPage
  },
  posStmtJobsData: state => {
    return state.posStmtJobsData
  },
  posStmtJobsRows: state => {
    if (!state.posStmtJobsData) return []
    return state.posStmtJobsData.rows
  },
  error: state => {
    return state.error
  }
}

const actions = {
  async loadPosStmtJobs({ commit, state }) {
    commit('clearError');
    try {
      var params = {
        page: state.selectedPage,
        size: state.pageSize,
      }
      const response = await api.getPosStmtJobs(params)
      console.log("Position Statement Jobs data loaded: ", response.data)
      commit('setPosStmtJobs', response.data)
    } catch(error) {
      console.error("loadPosStmtJobs:", error)
      commit('setError', { 
        message: "Failed to load position statement jobs",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  setSelectedPage( { commit, dispatch }, selectedPage) {
    commit('setSelectedPage', selectedPage)
    dispatch('loadPosStmtJobs')
  },

  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
