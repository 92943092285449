import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    ledgerData: null,
    startDateSelected: null,
    endDateSelected: null,
    ledgerId: null,
    error: null
  }
}

const state = getDefaultState();

const mutations = {
  setLedgerData(state, values) {
    state.ledgerData = values
  },
  setStartDateSelected(state, startDateSelected) {
    state.startDateSelected = startDateSelected
  },
  setEndDateSelected(state, endDateSelected) {
    state.endDateSelected = endDateSelected
  },
  setLedgerId(state, ledgerId) {
    state.ledgerId = ledgerId
  },
  setError(state,value) {
    state.error = value
  },
  clearError(state) {
    state.error = null
  }
}

const getters = {
  ledgerData: state => {
    return state.ledgerData
  },
  transactions: state => {
    return state.ledgerData != null ? state.ledgerData.transactions : []
  },
  error: state => {
    return state.error
  }
}

const actions = {
  loadPositionStatement({ commit, state }) {
    commit('clearError')
    api.getLedgerPositionStatement(state.ledgerId, {
      fromDate: mixins.formatJsDate_localTZ(state.startDateSelected), 
      toDate: mixins.formatJsDate_localTZ(state.endDateSelected), 
    }).then( (response) => {
        console.log("ledger loaded: ", response.data);
        commit('setLedgerData', response.data)
      }).catch(error => {
      console.error("failed to load Customer Statement:", error)
      console.log("!!!!", mixins.formatApiErrorMessage(error) )
      commit('setError', { 
        message: "Failed to load Customer Statement",
        reason: mixins.formatApiErrorMessage(error) 
      })
    })
  },
  sendPositionStatement({commit, state}) {
    commit('clearError')
    return api.sendLedgerPositionStatement(state.ledgerId, {
      fromDate: mixins.formatJsDate_localTZ(state.startDateSelected), 
      toDate: mixins.formatJsDate_localTZ(state.endDateSelected), 
    }).then( (response) => {
        console.log("sent position statement: ", response.data);
        return response
    }).catch(error => {
      console.error("failed to send statement:", error)
      commit('setError', { 
        message: "Error sending statement",
        reason: mixins.formatApiErrorMessage(error)
      })
    })
  },
  setSelectedDateRange({ commit, dispatch }, dateRange) {
    commit('setStartDateSelected', dateRange.startDate)
    commit('setEndDateSelected', dateRange.endDate)
    dispatch('loadPositionStatement')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}