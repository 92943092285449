import api from '@/Api'

const getDefaultState = () => {
  return {
    addCurrencyModalVisible: false,
    confirmDisableModalVisible: false,
    systemCurrencies: '',
    currencies: '',
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setConfirmDisableModalVisible(state, value) {
    state.confirmDisableModalVisible = value
  },
  setAddCurrencyModalVisible(state, value) {
    state.addCurrencyModalVisible = value
  },
  setCurrencies(state, currencies) {
    state.currencies = currencies
  },
  setSystemCurrencies(state, systemCurrencies) {
    state.systemCurrencies = systemCurrencies
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  confirmDisableModalVisible: state => {
    return state.confirmDisableModalVisible
  },
  addCurrencyModalVisible: state => {
    return state.addCurrencyModalVisible
  },
  availableCurrencies: state => {
    if (state.currencies == null) return null
    if (state.systemCurrencies == null) return state.currencies

    let systemCodes = state.systemCurrencies.map(item => item.currencyCode)
    return state.currencies.filter(item => !systemCodes.includes(item.code));
  },
  currencies: state => {
    return state.currencies
  },
  error: state => {
    return state.error
  },
  systemCurrencies: state => {
    return state.systemCurrencies
  }
}

const actions = {
  async loadSystemCurrencies({ commit }) {
    commit('clearError');
    try {
      const response = await api.getSystemCurrencies()
      console.log("SystemCurrency data loaded: ", response.data)
      commit('setSystemCurrencies', response.data.rows)
    } catch(error) {
      console.error("getSystemCurrencies:", error)
      commit('setError', { 
        message: "Failed to load system currencies",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  async loadCurrencies({ commit }) {
    try {
      const response = await api.getCurrencies()
      console.log("Currency data loaded: ", response.data)
      commit('setCurrencies', response.data.rows)
    } catch(error) {
      console.error("getCurrencies:", error)
      commit('setError', { 
        message: "Failed to load currencies",
        reason: error.message
      })
    }
  },
  async addSystemCurrency({ commit, dispatch}, currencyCode) {
    try {
      const response = await api.addSystemCurrency(currencyCode)
      console.log('reponseSTATUS', response)
      if (response.status) {
        console.log("Create Currency data loaded: ", response.data);
        dispatch('loadSystemCurrencies')
      }
    } catch(error) {
      console.error("Create Currency error:", error)
      commit('setError', { 
        message: "Failed to add new currency",
        reason: error.message
      })
    } 
  },
  async disableSystemCurrency({ commit, dispatch }, currencyCode) {

    try {
      const response = await api.disableSystemCurrency(currencyCode)
      if (response.status == 200) {
        console.log("disabled currency success")
        dispatch('loadSystemCurrencies')
      } else {
        console.error("Unable to disable currency", response)
        commit('setError', { 
          message: "Failed disable currency",
          reason: "%s:%s" % (response.status, response.data)
        })
      }
    } catch(error) {
        this.$log.debug("Failed to disable currency:", error)
        commit('setError', { 
          message: "Failed disable currency",
          reason: error.message
        })
    }
  },
  async cancelLoadCurrencies({ commit }) {
    commit('setAddCurrencyModalVisible', false);
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
