import api from '@/Api'

const getDefaultState = () => {
  return {
    selectedStatusList: ['ACTIVE'],
    filterShowOrgOnly: true,
    filterShowCustomerOnly: false,
  }
}

const state = getDefaultState();

const mutations = {
  setSelectedStatusList(state, values) {
    state.selectedStatusList = values
  },
  setFilterShowOrgOnly(state, value) {
    state.filterShowOrgOnly = value
  },
  setFilterShowCustomerOnly(state, value) {
    state.filterShowCustomerOnly = value
  },
  setAccountData(state,value) {
    state.accountData = value
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState());
  }
}

const getters = {
  selectedStatusList: state => {
    return state.selectedStatusList
  },
  filterShowCustomerOnly: state => {
    return state.filterShowCustomerOnly
  },
  filterShowOrgOnly: state => {
    return state.filterShowOrgOnly
  },
  accountDataFiltered: state => {
    return state.accountData
  }
}

const actions = {
  async loadAccounts({ commit, state }) {
    commit('clearError')
    try {
      var params = new URLSearchParams( {
        size: 5000,
        customerOnly: state.filterShowCustomerOnly,
        includeManaged: !state.filterShowOrgOnly,
      })
      state.selectedStatusList.forEach( status => {
        params.append('status', status)
      })

      const response = await api.getAccountsTree(params)
      console.log("accounts loaded: ", response.data);
      commit('setAccountData', response.data.rows)
    } catch(error) {
      console.error("failed to load accounts:", error)
      commit('setError', { 
        message: "Failed to load accounts",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  setSelectedStatusList({commit, dispatch}, value) {
    commit('setSelectedStatusList', value)
    dispatch('loadAccounts')
  },
  setFilterShowOrgOnly({commit, dispatch}, value) {
    commit('setFilterShowOrgOnly', value)
    dispatch('loadAccounts')
  },
  setFilterShowCustomerOnly({commit, dispatch}, value) {
    commit('setFilterShowCustomerOnly', value)
    dispatch('loadAccounts')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}