import axios from 'axios'
import { Dialog } from '@syncfusion/ej2-popups'

const DLG_VERSION_UPDATE = "e-version-update"

const content = `
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <p class="mt-2"><strong>A new version of ROMA is now available</strong></p>
        <p class="mt-2">Click 'reload' to load the new version now (or close the popup and do a manual browser refresh later)</p>
      </div>
    </div>
  </div>
`

const onClickReload = function() {
  window.location.reload()
}

export default {

  checkNewVersion(currentHash) {

    return axios.create({
      baseURL: '/',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-type': 'application/json'
      }
    }).get('version.json' + '?t=' + new Date().getTime())
      .then(response => {

        const remoteHash = response.data.hash
        // version check will only fail if we explicitly have a hash mismatch,
        // otherwise function will return true on all other error paths (to avoid errors if 
        // server temporariy returns an error)
        if ( currentHash !== remoteHash) {
          return true
        }
        return false
      })
      .catch(error => {
        console.log('error', error)
        return false
      })
      
  },

  createPopup() {
    
    const dialogElement = document.createElement('div', { 'className': DLG_VERSION_UPDATE})
    document.body.appendChild(dialogElement)

    var options = {
      header: 'ROMA Update',
      content: content,
      isModal: true,
      showCloseIcon: true,
      allowDragging: false,
      closeOnEscape: true,
      position: { X: 'center', Y: 'center' },
      width: "500",
      height: "auto",
      "zIndex": 1000000,
      buttons: [
        { click: onClickReload, buttonModel: { content: 'RELOAD', isPrimary: true } }
      ]
    }

    var dlgObj = new Dialog(options, dialogElement)
    dlgObj.close = function() {
      dlgObj.destroy()
      console.log('dlg close')
      dlgObj.target.classList.remove(DLG_VERSION_UPDATE)
    }
    dlgObj.overlayClick = function() {
      console.log('overlay click')
      dlgObj.hide()
    }
  }
}