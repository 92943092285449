import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    activityData: [],
    filterActiveOnly: true, 
    filterHideNoActivity: true,
    lastClosedAccountingDate: null,
    baseCurrencyMode: false,
    selectedDateRange: null,
    customerCodeId: null,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setActivityData(state, activityData) {
    state.activityData = activityData
  },
  setError(state, error) {
    state.error = error
  },
  setFilterActiveOnly(state,value) {
    state.filterActiveOnly = value
  },
  setFilterHideNoActivity(state,value) {
    state.filterHideNoActivity = value
  },
  setSelectedDateRange(state, selectedDateRange) {
    state.selectedDateRange = selectedDateRange
  },
  setLastClosedAccountingDate(state, lastClosedAccountingDate) {
    state.lastClosedAccountingDate = lastClosedAccountingDate
  },
  setBaseCurrencyMode(state, value) {
    state.baseCurrencyMode = value
  },
  setCustomerCodeId(state, customerCodeId) {
    state.customerCodeId = customerCodeId
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  activityData: state => {
    return state.activityData == null ? [] : state.activityData.rows
  },
  totalsData: state => {
    return state.activityData?.totals
  },
  baseCurrencyCode: state => {
    return state.activityData && state.activityData.baseCurrencyCode
  },
  isLiveActivityData: state => {
    return state.activityData != null ? state.activityData.isLive : false
  },
  requestQueryParams: (state) => (format) => {
    const fromDate = state.selectedDateRange[0]
    const toDate = state.selectedDateRange[1]
    return {
      fromDate: mixins.formatJsDate_localTZ(fromDate),
      toDate: mixins.formatJsDate_localTZ(toDate),
      activeOnly: state.filterActiveOnly,
      hideNoActivity: state.filterHideNoActivity,
      format: format,
      base: state.baseCurrencyMode,
      ...(state.customerCodeId && { customerCodeId: state.customerCodeId})
    }
  },
  baseCurrencyMode: state => {
    return state.baseCurrencyMode
  },
  customerCodeId: state => {
    return state.customerCodeId
  },
  selectedDateRange: state => {
    return state.selectedDateRange
  },
  reportEndDate: state => {
    return state.activityData.toDate
  },
  filterActiveOnly: state => {
    return state.filterActiveOnly
  },
  filterHideNoActivity: state => {
    return state.filterHideNoActivity
  }
}

const actions = {
  loadAccountActivity({ commit, getters}) {
    commit('clearError')
    return api.getAccountActivity(getters.requestQueryParams())
      .then( response => {
        console.log("Activity data loaded: ", response.data)
        commit('setActivityData', response.data)
        return response
      }).catch(error =>  {
        console.error("get account activity data:", error)
        commit('setError', { 
          message: "Failed to load Account Activity Data",
          reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
        })
      })
  },
  setSelectedDateRange({ commit, dispatch }, range) {
    commit('setSelectedDateRange', range)
    dispatch('loadAccountActivity')
  },
  setFilterActiveOnly({ commit, dispatch},value) {
    commit('setFilterActiveOnly', value)
    dispatch('loadAccountActivity')
  },
  setFilterHideNoActivity({ commit, dispatch},value) {
    commit('setFilterHideNoActivity', value)
    dispatch('loadAccountActivity')
  },
  setCustomerCodeId({commit, dispatch}, value) {
    commit('setCustomerCodeId', value)
    dispatch('loadAccountActivity')
  },
  setBaseCurrencyMode({commit, dispatch}, value) {
    commit('setBaseCurrencyMode', value)
    dispatch('loadAccountActivity')
  },
  resetState({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
