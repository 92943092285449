const getDefaultState = () => {
  return {
    error: null,
  }
}

const state = getDefaultState()

const mutations = {
  setError(state, error) {
    state.error = error
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
}

const actions = {
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}