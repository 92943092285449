
import api from '@/Api'
import { cloneDeep } from 'lodash-es'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    error: null,
    customerCodeId: null,
    customer: null,
    customerAccountNotes: { result: [], count: 0 },
    customerLedgers: [],
    linkableAccounts: [],
    selectedAccountData: null,
    customerAccountNotesSort: ['createdAt,desc'],
    customerNotesSort:['createdAt,desc'],
    customerContactSort: ['type,asc'],
  }
}

const state = getDefaultState()

const mutations = {
  setCustomerCodeId(state, code) {
    state.customerCodeId = code
  },
  setCustomer(state, customer) {
    state.customer = customer;
  },
  setCustomerLedgers(state, customerLedgers) {
    state.customerLedgers = customerLedgers
  },
  setCustomerAccountNotes(state, customerAccountNotes) {
    state.customerAccountNotes = customerAccountNotes
  },
  setCustomerAccountNotesSort(state, sort) {
    state.customerAccountNotesSort = sort
  },
  setCustomerNotesSort(state,sort) {
    state.customerNotesSort = sort
  },
  setCustomerContactSort(state,sort) {
    state.customerContactSort = sort
  },
  setLinkableAccounts(state, linkableAccounts) {
    state.linkableAccounts = linkableAccounts
  },
  setSelectedAccountData(state, selectedAccountData) {
    state.selectedAccountData = selectedAccountData
  },
  setError(state, error) {
    state.error = error
  },
  resetCustomerAccountNotes(state) {
    state.customerAccountNotes = { result: [], count: 0 }
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  },
}

const getters = {
  customer: state => {
    return state.customer
  },
  customerAccounts: state => {
    if (state.customerLedgers.length == 0) {
      return { result: [], count: 0 }
    } else {
      return {
        result: cloneDeep(state.customerLedgers.accounts),
        count: state.customerLedgers.accounts.length
      }
    }
  },
  customerAccountNotes: state => {
    return cloneDeep(state.customerAccountNotes)
  },
  customerLedgers: state => {
    return state.customerLedgers.ledgers
  },
  linkableAccounts: state => {
    return state.linkableAccounts
  },
  error: state => {
    return state.error
  },
}

const actions = {
   async loadCustomer({ commit, state }) {

    try {
      const response = await api.getCustomer(state.customerCodeId, {})
      console.log("customer loaded: ", response.data)
      commit('setCustomer', response.data)
    } catch (error) {
      console.log("failed to load customer:", error)
      commit('setError', { 
        message: "Failed to load customer: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  async updateCustomer({ commit }, customer) {
    try {
      const response = await api.updateCustomer(customer.customerCodeId, customer)
      console.log("customer updated: ", response.data)
      commit('setCustomer', response.data)
    } catch (error) {
      console.log("failed to update customer:", error.response.data)
      commit('setError', { 
        message: "Failed to update customer: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" 
                  : error.response.data?.message || error.response.data.error
      })
    }
  },
  async updateCustomerAccount({ commit,state }, data) {
    try {
      const response = await api.updateCustomerAccount(state.customerCodeId, data.customerAccountId, data)
      console.log("customer account updated: ", response.data)
    } catch (error) {
      console.log("failed to update customer account:", error)
      commit('setError', { 
        message: "Failed to update customer account: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  async loadCustomerLedgers( {commit, state}) {
    try {
      const response = await api.getCustomerLedgers(state.customerCodeId) 
      console.log("account ledgers loaded: ", response.data)
      commit('setCustomerLedgers', response.data)
    } catch (error) {
      console.log("failed to load customer ledgers", error)
      commit('setError', { 
        message: "Failed to load customer ledgers: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  async loadLinkableAccounts( {commit}, currencyCode) {
    return api.getLinkableAccounts({currencyCode: currencyCode})
    .then(response => {
      if (response.status == 200) {
        console.log("linkable accounts added: ", response.data);
        commit('setLinkableAccounts', response.data)
      }
    })
    .catch(error => {
      console.error("failed to get linkable accounts:", error)
      commit('setError', { 
        message: "Failed to get linkable accounts",
        reason: error.message
      })
    })
  },
  async deleteCustomerLedger( {commit}, customerLedgerId) {

    return api.deleteCustomerLedger(customerLedgerId)
      .then(response => {
        if (response.status == 202) {
          console.log("deleted customer ledger: ", response.data);
        }
      })
      .catch(error => {
        console.error("failed to delete customer ledger:", error)
        commit('setError', { 
          message: "Failed to delete customer ledger",
          reason: error.message
        })
    })
  },
  async linkAccount( {commit, state}, account) {
    return api.linkAccount(state.customerCodeId, account)
    .then(response => {
      if (response.status == 202) {
        console.log("account linked: ", response.data);
      }
    })
    .catch(error => {
      console.error("failed to link account:", error)
      commit('setError', { 
        message: "Failed to link account",
        reason: error.message
      })
    })
  },
  async createCustomerNote( {commit, state}, data) {
    return api.addCustomerNote(state.customerCodeId, data)
    .then(response => {
      if (response.status == 200) {
        console.log("customer note added: ", response.data);
      }
    })
    .catch(error => {
      console.error("failed to add customer note:", error)
      commit('setError', { 
        message: "Failed to add customer note",
        reason: error.message
      })
    })
  },
  async loadCustomerAccountNotes({commit, state}) {
    var params = new URLSearchParams() 
    for (let sort of state.customerAccountNotesSort) {
      params.append('sort', sort)
    }
    return api.getCustomerAccountNotes(state.customerCodeId, state.selectedAccountData.customerAccountId, params)
      .then(response => {
        if (response.status == 200) {
          console.log("customer account notes loaded: ", response.data)
          commit('setCustomerAccountNotes', response.data)
        }
      }).catch(error => {
        console.error("failed to load customer account notes:", error)
        commit('setError', { 
          message: "Failed to customer account notes",
          reason: error.message
        })
      })
  },
  async deleteCustomerNote( {commit, state}, data) {
    return api.deleteCustomerNote(state.customerCodeId, data[0].customerNoteId)
      .then(response => {
        if (response.status == 200) {
          console.log("customer note deleted: ", response.data)
        }
      })
      .catch(error => {
        console.error("failed to delete customer note:", error)
        commit('setError', { 
          message: "Failed to delete customer note",
          reason: error.message
        })
      })
  },
  async updateCustomerNote( {commit, state}, note) {
    return api.updateCustomerNote(state.customerCodeId, note.customerNoteId, note)
      .then(response => {
        if (response.status == 200) {
          console.log("customer note updated: ", response.data)
        }
      })
      .catch(error => {
        console.error("failed to update customer note:", error)
        commit('setError', { 
          message: "Failed to update customer note",
          reason: error.message
        })
      })
  },
  async createCustomerContact( {commit, state}, contact) {
    return api.addCustomerContact(state.customerCodeId, contact)
    .then(response => {
      if (response.status == 200) {
        console.log("customer contact added: ", response.data);
      }
    })
    .catch(error => {
      console.error("failed to add customer contact:", error)
      commit('setError', { 
        message: "Failed to add customer contact",
        reason: error.message
      })
    })
  },
  async deleteCustomerContact( {commit, state}, contact) {
    return api.deleteCustomerContact(state.customerCodeId, contact[0].customerContactId)
      .then(response => {
        if (response.status == 200) {
          console.log("customer contact deleted: ", response.data)
        }
      })
      .catch(error => {
        console.error("failed to delete customer contact:", error)
        commit('setError', { 
          message: "Failed to delete customer contact",
          reason: error.message
        })
      })
  },
  async updateCustomerContact( {commit, state}, contact) {
    return api.updateCustomerContact(state.customerCodeId, contact.customerContactId, contact)
      .then(response => {
        if (response.status == 200) {
          console.log("customer contact updated: ", response.data)
        }
      })
      .catch(error => {
        console.error("failed to update customer contact:", error)
        commit('setError', { 
          message: "Failed to update customer contact",
          reason: error.message
        })
      })
  },
  async createCustomerLedger( {commit, state},ledger) {
    return api.addCustomerLedger(state.customerCodeId, ledger)
    .then(response => {
      if (response.status == 202) {
        console.log("customer ledger added: ", response.data);
      }
    })
    .catch(error => {
      console.error("failed to add customer ledger:", error)
      commit('setError', { 
        message: "Failed to add customer ledger",
        reason: mixins.formatApiErrorMessage(error)
      })
    })
  }, 

  reset({commit}) {
    commit('resetState')
  },
  clearError({commit}) {
    commit('setError', null)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}