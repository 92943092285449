import api from '@/Api'
import { cacheAction } from 'vuex-cache'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    accountingStatus: null,
  }
}

const state = getDefaultState()

const mutations = {
  setAccountingStatus(state, accountingStatus) {
    state.accountingStatus = accountingStatus
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  lastActiveAccountingDay: state => {
    return state.accountingStatus && state.accountingStatus.lastActive
      ?  mixins.getJsDateFromIso_localTZ(state.accountingStatus.lastActive.accountingDate) : null
  },
  lastActiveEnteredEvents: state => {
    return state.accountingStatus && state.accountingStatus.lastActive
      ? state.accountingStatus.lastActive.enteredEvents : 0
  },
  lastActiveEventsFullyEnteredAt: state => {
    return state.accountingStatus && state.accountingStatus.lastActive
      ? state.accountingStatus.lastActive.eventsFullyEnteredAt : null
  },
  lastActiveFinishedAt: state => {
    return state.accountingStatus && state.accountingStatus.lastActive
      ? state.accountingStatus.lastActive.finishedAt : null
  },
  lastActiveReconciled: state => {
    return state.accountingStatus && state.accountingStatus.lastActive 
      ? state.accountingStatus.lastActive.reconciled : false
  },
  lastActiveJournalEntry: state => {
    return state.accountingStatus && state.accountingStatus.lastActive 
      ? state.accountingStatus.lastActive.journalEntry : false
  },
  lastActiveEndOfWeek: (state,getters) => {
    if (getters.lastActiveAccountingDay == null) return null
    var d = new Date(getters.lastActiveAccountingDay)
    d.setDate(d.getDate() - d.getDay())
    return d
  },
  lastActiveStartOfWeek: (state, getters) => {
    if (getters.lastActiveEndOfWeek == null) return null
    var d = new Date(getters.lastActiveEndOfWeek)
    d.setDate(d.getDate() - 6)
    return d
  },
  lastCbReconciliation: state => {
    return state.accountingStatus && state.accountingStatus.lastCbReconciliation
      ? mixins.getJsDateFromIso_localTZ(state.accountingStatus.lastCbReconciliation) : null
  },
  firstCbReconciliation: state => {
    return state.accountingStatus && state.accountingStatus.firstCbReconciliation
      ? mixins.getJsDateFromIso_localTZ(state.accountingStatus.firstCbReconciliation) : null
  },
  lastClReconciliation: state => {
    return state.accountingStatus?.lastClReconciliation
      ? mixins.getJsDateFromIso_localTZ(state.accountingStatus.lastClReconciliation) : null
  },
  firstClReconciliation: state => {
    return state.accountingStatus?.firstClReconciliation
      ? mixins.getJsDateFromIso_localTZ(state.accountingStatus.firstClReconciliation) : null
  },
  lastFxRateDate: state => {
    return state.accountingStatus && state.accountingStatus.lastFxRateDate 
      ? mixins.getJsDateFromIso_localTZ(state.accountingStatus.lastFxRateDate) : null
  },
  activeEnteredEvents: state => {
    return state.accountingStatus && state.accountingStatus.active
      ? state.accountingStatus.active.enteredEvents : 0
  },
  activeNonEnteredEvents: state => {
    return state.accountingStatus && state.accountingStatus.active
      ? state.accountingStatus.active.nonEnteredEvents : 0
  },
  activeEnteredEventsPercent: (state,getters) => {
    if (getters.activeEnteredEvents == 0 && getters.activeNonEnteredEvents == 0) return 0
    return getters.activeEnteredEvents / (getters.activeEnteredEvents + getters.activeNonEnteredEvents)
  },
  activeEventsFullyEnteredAt: state => {
    return state.accountingStatus && state.accountingStatus.active
      ? state.accountingStatus.active.eventsFullyEnteredAt : 0
  },
  activeEndOfDayScheduledAt: state => {
    return state.accountingStatus && state.accountingStatus.active
      ? state.accountingStatus.active.endOfDayScheduledAt : null
  },
  activeFinishedAt: state => {
    return state.accountingStatus && state.accountingStatus.active
      ? state.accountingStatus.active.finishedAt : null
  },
  activeAccountingDay: state => {
    return state.accountingStatus && state.accountingStatus.active
      ? mixins.getJsDateFromIso_localTZ(state.accountingStatus.active.accountingDate) : null
  },
}

const actions = {
  loadAccountingStatusCached: cacheAction(({ cache, commit }) => {
    return cache.dispatch({type: 'requestAccountingStatus', timeout: 60000})
      .then(response => {
        console.log("accounting status data loaded: ", response.data)
        commit('setAccountingStatus', response.data)
      })
      .catch(error => {
        console.log("failed to load accounting status data:", error)
      })
  }),
  requestAccountingStatus() {
    console.log('requesting AccountingStatus from remote')
    return api.getAccountingStatus()
  },
  clearAccountingStatusCache: cacheAction(({ cache }) => {
    cache.clear('requestAccountingStatus') 
  }),
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}