import VueJwtDecode from 'vue-jwt-decode'

const state = () => ({
  token: '',
  roles: [],
  username: '',
})

const mutations = {
  setToken(state, token) {
    state.token = token
    const decoded = VueJwtDecode.decode(token)
    state.roles = decoded.realm_access.roles
    state.username = decoded.preferred_username
  },
  logout(state) {
    state.token = ''
    state.roles = []
    state.username = ''
  }
}

const getters = {
  /*
  isCustomerAccount: state => {
    return (state.account && state.account.isCustomer)
  },
  */
  avatarName: state => {
    if (!state.username) return ""
    return state.username.substring(0,2).toUpperCase();
  },
  roles: state => {
    return state.roles
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}