import api from '@/Api'

const getDefaultState = () => {
  return {
    partners: null,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {  
  setPartners(state, partners) {
    state.partners = partners
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  partners: state => {
    return state.partners
  },
  error: state => {
    return state.error
  },
}

const actions = {
  async loadPartners({ commit }) {
    commit('clearError');
    try {
      const response = await api.getPartners()
      console.log("SystemCurrency data loaded: ", response.data)
      commit('setPartners', response.data.rows)
    } catch(error) {
      console.error("error loading partners:", error)
      commit('setError', { 
        message: "Failed to load partners",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  addPartner({ commit, dispatch }, partner) {
    return api.addPartner(partner)
    .then(response => {
      if (response.status == 201) {
        console.log("partner created: ", response.data);
        dispatch('loadPartners')
      }
    })
    .catch(error => {
      console.error("failed to add partner:", error)
      commit('setError', { 
        message: "Failed to add partner",
        reason: error.message
      })
    })
  },
  updateCredentials({ commit }, partner) {
    return api.updatePartnerCredentials(partner)
      .then(response => {
        if (response.status == 201) {
          console.log("partner credentials updated")
        }
      })
      .catch(error => {
        console.error("failed to update credentials:", error)
        commit('setError', {
          message: "Failed to update credentials",
          reason: error.message
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}